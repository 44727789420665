import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Offer1 from "../assets/image/offer1.png"

function Category() {
  const { coursesByCategories } = require("../data.json")
  return (
    <Layout>
      <div className="flex flex-wrap items-center justify-center pt-52">
        {coursesByCategories.map(obj => (
          <Link to={"/courses/" + obj.id}>
            <div className="relative m-6">
              <img src={Offer1} className="height-career" />
              <div className="text-white bg-gray-800 bg-opacity-50 absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
                <p
                  className="text-4xl font-semibold text-center"
                  dangerouslySetInnerHTML={{ __html: obj.categoryName }}
                ></p>
                <p className="text-3xl mt-5">{obj.numOfCourses} courses</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export default Category
